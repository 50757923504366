import { graphql } from 'gatsby'
import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import images from "../images/gallery/";
import { Container, Row, Col } from "react-bootstrap"

const GalleryPage = () => {

    
    return (
        <Layout pageInfo={{ pageName: "gallery" }}>
            <Container className="mt-5">
                <SEO title="Gallery" />
                <h1>Photos from live shows</h1>
            </Container>
            <div className='gallery-container'>
                {images.map((img, index) => (
                    <img key={index} src={img} alt={img} className='img-fluid' />
                ))}
            </div>
        </Layout>
)}

export default GalleryPage
